import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);
//按需加载,当渲染其他页面时才加载其组件,并缓存,减少首屏加载时间
const Index = resolve => require(['@/views/index.vue'], resolve)
const Order = resolve => require(['@/views/order.vue'], resolve)
const Payment = resolve => require(['@/views/payment.vue'], resolve)


const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: '',
            component: Index,
            meta: {
                title: '道号堂',
                description: '五年号商出售，QQ号，tg号，telegram，微信号，抖音号，whatsapp,Line,推特，IG,tiktok,苹果id,谷歌邮箱，港卡，香港卡，陌陌，快手，小红书，百度，微博',
                keywords: 'QQ号，tg号，telegram，微信号，抖音号，whatsapp,Line,推特，IG,tiktok,苹果id,谷歌邮箱，港卡，香港卡，陌陌，快手，小红书，百度，微博'
            },
        } ,
        {
            path: '/order',
            name: 'order',
            component: Order,
            meta: {
                title: '道号堂',
                description: '五年号商出售，QQ号，tg号，telegram，微信号，抖音号，whatsapp,Line,推特，IG,tiktok,苹果id,谷歌邮箱，港卡，香港卡，陌陌，快手，小红书，百度，微博',
                keywords: 'QQ号，tg号，telegram，微信号，抖音号，whatsapp,Line,推特，IG,tiktok,苹果id,谷歌邮箱，港卡，香港卡，陌陌，快手，小红书，百度，微博'
            },
        },
        {
            path: '/payment',
            name: 'payment',
            component: Payment,
            meta: {
                title: '道号堂',
                description: '五年号商出售，QQ号，tg号，telegram，微信号，抖音号，whatsapp,Line,推特，IG,tiktok,苹果id,谷歌邮箱，港卡，香港卡，陌陌，快手，小红书，百度，微博',
                keywords: 'QQ号，tg号，telegram，微信号，抖音号，whatsapp,Line,推特，IG,tiktok,苹果id,谷歌邮箱，港卡，香港卡，陌陌，快手，小红书，百度，微博'
            },
        },

    ]
})
// 使用导航守卫动态设置标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router
